import { ReactNode } from 'react';
import { Translate } from 'next-translate';
import { NextRouter } from 'next/router';
import { Icon } from 'antd-v3';
import { briefcase } from '@utils/icons';
import {
  AppstoreOutlined,
  BankOutlined,
  BuildOutlined,
  BulbOutlined,
  IdcardOutlined,
  LineChartOutlined,
  PlusCircleOutlined,
  TableOutlined,
  TeamOutlined
} from '@ant-design/icons';
import Routes from '@services/Routes';
import { CAT_NEW_REQUEST, NEW_REQUEST_FROM_MENU, DATA_QUALITY_PAGE } from '@utils/analytics_constants';
import { attachTrackingAnalytics } from '@services/SegmentService';

import { PathMap } from '@components/platform_administration/modals/EditDashboardEmbeds/types';
import CommCenterIcon from './CommCenterIcon';

export const EXECUTIVE_DASHBOARD_KEY = 'executive_dashboard';

export const LOGISTICS_MARKET_REPORT_KEY = 'market_report';

export type MenuItem = {
  visible: boolean;
  path?: string;
  icon?: ReactNode;
  text: string | JSX.Element;
  title?: string; // use when the text is not a string to handle the collapsed menu variant
  type?: string;
  requirePageLoad?: boolean;
  onClick?: () => void;
};

type EmbedCode = {
  path: string;
  title: string;
  dashboard_url?: string;
};

const defaultMenuItems = (
  t: Translate,
  user: any,
  router: NextRouter,
  useProactiveRecommendations: boolean = false,
  unreadCount: number = 0,
  isAutomated: boolean = false,
  showInsightsMenuOption: boolean = false,
  enableAnomalousEventsTaggingForAwardingReports: boolean = false,
): MenuItem[] => {
  const isDeeplink = user.is_deeplink;
  const isBuyer = user.is_buyer && !isDeeplink;
  const isSuperAdmin = user.category === 'superadmin';
  const deeplinkContextID = user.deeplink_contextable_id;

  const menuItems: MenuItem[] = [
    {
      visible: !isSuperAdmin && !isDeeplink,
      path: '/bid_requests',
      icon: <AppstoreOutlined />,
      text: t('side_menu.dashboard')
    },
    {
      visible: isDeeplink,
      path: `/bid_requests/${deeplinkContextID}/dashboard`,
      icon: <AppstoreOutlined />,
      text: t('side_menu.request_overview')
    },
    {
      visible: isBuyer,
      path: '/new_bid_request',
      onClick: () => {
        attachTrackingAnalytics(NEW_REQUEST_FROM_MENU, { category: CAT_NEW_REQUEST });
        router.push('/new_bid_request');
      },
      icon: <PlusCircleOutlined />,
      text: t('side_menu.new_bid_request')
    },
    {
      title: t('general.messages'),
      visible: !isSuperAdmin && !isDeeplink,
      path: Routes.MESSAGES_PATH,
      text: <CommCenterIcon unreadCount={unreadCount} />
    },
    {
      title: t('general.messages'),
      visible: isDeeplink && !isAutomated,
      path: Routes.REQUEST_MESSAGES_PATH(deeplinkContextID),
      text: <CommCenterIcon unreadCount={unreadCount} />
    },
    {
      visible: isSuperAdmin,
      path: '/platform_administration/organizations',
      icon: <Icon component={briefcase} />,
      text: t('side_menu.organizations')
    },
    {
      visible: isSuperAdmin,
      path: '/platform_administration/users',
      icon: <TeamOutlined />,
      text: t('side_menu.users')
    },
    {
      visible: isSuperAdmin,
      path: '/platform_administration/bid_requests',
      icon: <PlusCircleOutlined />,
      text: t('side_menu.bid_requests')
    },
    {
      visible: isSuperAdmin,
      path: '/platform_administration/request_reports',
      icon: <TableOutlined />,
      text: t('side_menu.request_reports')
    },
    {
      visible: isBuyer,
      path: '/suppliers',
      icon: <IdcardOutlined />,
      text: t('side_menu.suppliers')
    },
    {
      visible: isBuyer,
      path: '/team',
      icon: <TeamOutlined />,
      text: t('side_menu.team')
    },
    // Uncomment when we have a need for internal services
    // {
    //   visible: isSuperAdmin,
    //   path: '/platform_administration/services',
    //   icon: <ToolOutlined />,
    //   text: t('side_menu.services')
    // },
    {
      visible: isBuyer && useProactiveRecommendations,
      path: Routes.RECOMMENDATIONS_PATH,
      icon: <BankOutlined />,
      text: t('side_menu.recommendations')
    },
    {
      visible: isBuyer && showInsightsMenuOption,
      path: '/insights',
      icon: <BulbOutlined />,
      text: t('side_menu.insights')
    },
    {
      visible: isBuyer && enableAnomalousEventsTaggingForAwardingReports,
      path: '/data-quality',
      icon: <BuildOutlined />,
      text: t('side_menu.data_quality'),
      onClick: () => {
        attachTrackingAnalytics(DATA_QUALITY_PAGE);
        router.push('/data-quality');
      },
    }
  ];

  return menuItems;
};

const addDashboardReports = (menuItems: MenuItem[], embedCodes: EmbedCode[], isBuyer: boolean) => {
  const base = {
    visible: isBuyer,
    icon: <LineChartOutlined />
  };
  const paths: PathMap = {};

  embedCodes.forEach(({ path: key, title: text, dashboard_url: dashboardUrl }) => {
    const path: string = `/reports/${key}`;

    if (paths[path]) return; // Already exists

    // If it's a verb report, it requires a fresh page load so api key is not expired.
    menuItems.push({
      ...base,
      path,
      text,
      requirePageLoad: !dashboardUrl
    });

    paths[path] = true;
  });

  return menuItems;
};

type Params = {
  t: Translate;
  user: any;
  router: NextRouter;
  unreadCount?: number;
  showVerbOrSigmaReports?: boolean;
  useProactiveRecommendations?: boolean;
  businessIntelligenceTool?: string;
  enableExecutiveSavingsDashboard?: string;
  useLogisticsMarketReport?: string;
  isAutomated: boolean;
  showInsightsMenuOption?: boolean;
  enableAnomalousEventsTaggingForAwardingReports?: boolean;
};

export const getSideMenuItems = (params: Params): MenuItem[] => {
  const {
    t,
    user,
    router,
    unreadCount,
    showVerbOrSigmaReports,
    useProactiveRecommendations,
    enableExecutiveSavingsDashboard,
    useLogisticsMarketReport,
    isAutomated,
    showInsightsMenuOption,
    enableAnomalousEventsTaggingForAwardingReports,
  } = params;
  const menuItems: MenuItem[] = defaultMenuItems(
    t,
    user,
    router,
    useProactiveRecommendations,
    unreadCount,
    isAutomated,
    showInsightsMenuOption,
    enableAnomalousEventsTaggingForAwardingReports,
  );

  const isAdmin = user.role === 'admin' || user.role === 'owner';

  if (!showVerbOrSigmaReports) return menuItems;
  const isBuyer = user.is_buyer && !user.is_deeplink;

  let embedCodes: any[] = [];

  if (user.sigma_dashboard_embeds?.length > 0) embedCodes = embedCodes.concat(user.sigma_dashboard_embeds);

  if (!enableExecutiveSavingsDashboard) embedCodes = embedCodes.filter(({ path }) => path !== EXECUTIVE_DASHBOARD_KEY);

  // do not show menu item if user is not admin/owner for logistics market report.
  if (!useLogisticsMarketReport || !isAdmin) embedCodes = embedCodes.filter(({ path }) => path !== LOGISTICS_MARKET_REPORT_KEY);

  return addDashboardReports(menuItems, embedCodes, isBuyer);
};
